<template>
  <div>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />
    
    <v-container class="py-0">
      <section>
        <v-row justify="center">
          <v-img src="@assets/images/HiSolver--Students-1.jpg">
            <v-overlay
              absolute
              opacity="0.3"
              color="#212121"
              z-index="-1"
            ></v-overlay>

            <v-toolbar
              extended
              elevation="0"
              color="transparent"
            >
              <v-container>
                <div class="my-16"></div>
                <v-row
                  justify="center"
                  class="white--text mx-auto"
                  style="position: relative;"
                >
                  <span :class="`text-h${txtBreakPoint} font-weight-bold`">
                    HiSolver
                  </span>
                  <v-img
                    style="position: absolute; bottom: 0; right: 0;"
                    src="@/assets/images/beta_logo.png"
                    max-width="60"
                  ></v-img>
                </v-row>
              </v-container>
            </v-toolbar>

            <v-responsive class="mx-auto mb-8 text-center">
              <span
                class="grey--text text--lighten-4 font-weight-regular"
                style="font-size: 180%;"
              >
                {{ $t('landing.newStudy') }}
              </span>
              
              <br />

              <span
                class="grey--text text--lighten-4 font-weight-regular"
                style="font-size: 180%;"
              >
                {{ $t('landing.sharingKnowledge') }}
              </span>
            </v-responsive>

            <v-footer
              absolute
              height="100"
              color="transparent"
            >
              <v-container>
                <v-row justify="center">
                  <v-btn
                    :x-large="largeThreshold"
                    :large="!largeThreshold"
                    color="#FFA219"
                    class="mx-3"
                    style="border-radius: 12px;"
                    @click="showVideo = true"
                  >
                    {{ $t('landing.watch') }}
                  </v-btn>

                  <v-dialog v-model="showVideo">
                    <VideoPlayer
                      style="max-height: 200px;"
                      videoId="demo-video"
                      url="https://hisolverlab.s3.amazonaws.com/demo1mobile.mp4"
                      thumbnail="https://hisolverlab.s3.amazonaws.com/Screenshot+2023-08-09+at+5.56.32+PM.png"
                      :options="{
                        controls: true,
                        autoplay: true,
                        responsive: true,
                        playbackRates: [0.5, 1, 1.5, 2] 
                      }"
                      :isPreview="true"
                      :mediaViewer="true"
                    />
                  </v-dialog>
                </v-row>
              </v-container>
            </v-footer>
          </v-img>
        </v-row>
      </section>

      <v-container>
        <v-row justify="center">
          <span
            class="text-center text-h6 font-weight-bold"
            v-html="$t('landing.harvard_hook')"
          ></span>
        </v-row>

        <div class="my-4"></div>

        <v-row justify="center">
          <v-col cols="6" md="3">
            <v-img
              max-width="180"
              src="@assets/images/harvard.png"
              class="mx-auto d-block"
            ></v-img>
          </v-col>
        </v-row>

        <div
          class="mb-8 mx-auto text-center grey--text text--darken-1 font-weight-regular"
          style="max-width: 720px;"
          v-html="$t('landing.description')"
        ></div>
      </v-container>

      <section>
        <v-row justify="center">
          <v-img
            src="@assets/images/HiSolver-Lights---Mobile.jpg"
            class="white--text align-center"
          >
            <v-container>
              <v-row justify="center">
                <div class="text-h6 white--text">
                  Coming for iOS and Android
                </div>
              </v-row>

              <v-divider
                color="white"
                class="my-4"
              />

              <v-row justify="center">
                <v-btn
                  v-if="waitlistState === 'NOT_SIGNED'"
                  x-large
                  color="white"
                  class="hs-rounded-12"
                  @click="waitlistState = 'OPEN'"
                >
                  be notified
                </v-btn>
              </v-row>

              <v-fade-transition hide-on-leave>
                <v-row v-if="waitlistState != 'NOT_SIGNED'" justify="center">
                  <v-col cols="10">
                    <v-text-field
                      v-model="email"
                      ref="waitlistInput"
                      filled
                      :disabled="waitlistState === 'SIGNED'"
                      :loading="loading"
                      validate-on-blur
                      background-color="white"
                      placeholder="email"
                      class="hs-rounded-text"
                      :append-icon="waitlistState === 'SIGNED' ? mdiCheck : mdiSend"
                      :rules="[v => (/.+@.+\..+/).test(v) || 'Invalid email address.']"
                      @click:append="signWaitlist"
                      @input="formatEmail"
                    />
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-container>
          </v-img>
        </v-row>
      </section>

      <section>
        <v-container class="text-center">
          <v-avatar
            tile
            size="180"
            color="white"
            class="elevation-12 mb-6 mt-8 rounded-xl"
          >
            <v-img
              src="@assets/images/Hierarchical Solver.jpeg"
              max-height="180"
              max-width="180"
            />
          </v-avatar>
        </v-container>
      </section>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { SnackBar } from '@components/App'
import { VideoPlayer } from '@components/Video';
import { mdiCheck, mdiSend } from '@mdi/js'
import API from '@api'

export default {
  components: {
    VideoPlayer,
    SnackBar
  },

  created() {
    const search = window.location.search;
    if (
      (search.includes("code=") || search.includes("error=")) &&
      search.includes("state=")
    ) {
      this.loading = true
    }
  },

  data () {
    return {
      mdiCheck,
      mdiSend,
      loading: false,
      snackMsg: '',
      snackOn: false,
      errMsg: '',
      email: '',
      waitlistState: 'NOT_SIGNED'
    }
  },

  computed: {
    largeThreshold () {
      return this.$vuetify.breakpoint.width > 400
    },

    txtBreakPoint () {
      return this.$vuetify.breakpoint.smAndUp
        ? 1
        : this.largeThreshold
        ? 2
        : 3
    }
  },

  methods: {
    formatEmail() {
      this.email = this.email.replace(/\s/g, "").toLowerCase()
    },

    async signWaitlist() {
      if (this.$refs.waitlistInput.validate()) {
        this.loading = true
        try {
          this.waitlistState = 'SIGNED'
          await API().post('waitlist', {
            email: this.email
          })
          
        
          this.snackMsg = 'waitlistConfirmed'
          this.snackOn = true
        } catch(err) {

          this.waitlistState = 'NOT_SIGNED'

        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>